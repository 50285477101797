const $ = require('jquery');
const {translate} = require("../functions");

window.addEventListener("load", () => {
    const requestPermission = $('#value_request_permission').val();

    if (requestPermission === '1' && "Notification" in window) {
        handleNotificationPermission();
    }
});

async function regWorker() {
    try {
        // Enregistrer le service worker
        const assetVersion = $('#value_asset_version').val();
        await navigator.serviceWorker.register(`/worker.js?v=${assetVersion}`, { scope: "/" });

        // Souscrire au serveur push
        const readyReg = await navigator.serviceWorker.ready;
        const subscription = await readyReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: $('#value_vapid_public_key').val()
        });

        // Enregistrer l'abonnement dans la base de données
        saveSubscription(subscription);
    } catch (err) {
        logError('Service Worker registration failed', err);
    }
}

function saveSubscription(subscription) {
    const endpoint = subscription.endpoint;
    const key = subscription.getKey('p256dh');
    const token = subscription.getKey('auth');

    $.ajax({
        url: Routing.generate('pushsubscriber_save'),
        type: 'POST',
        data: {
            endpoint,
            key: encodeKey(key),
            token: encodeKey(token)
        }
    })
        .done(response => logInfo(response.data))
        .fail((jqXHR, textStatus, errorThrown) => {
            logError(`AJAX error: ${textStatus}, ${errorThrown}`);
        });
}

function encodeKey(key) {
    return btoa(String.fromCharCode(...new Uint8Array(key)));
}

function handleNotificationPermission() {
    if (Notification.permission === "granted") {
        logInfo('Notification allowed');
        regWorker().catch(err => logError('Worker registration error', err));
    } else {
        displayNotificationRequest();
    }
}

function displayNotificationRequest() {
    $('.js-flash-message-error .text').html(
        `${translate('crm.push_notification.message')} 
        <a href="#" class="button-default" id="enableNotifications">
            ${translate('crm.push_notification.authorize')}
        </a>`
    );
    $('.js-flash-message-error').removeClass('--hidden');
    $('#enableNotifications').on('click', event => {
        event.preventDefault();
        requestNotificationPermission();
    });
}

window.requestNotificationPermission = function() {
    Notification.requestPermission()
        .then(permission => {
            if (permission === "granted") {
                hideNotificationRequest();
                logInfo('Notification allowed');
                regWorker().catch(err => logError('Worker registration error', err));
            } else {
                logError('Notification not allowed');
            }
        })
        .catch(err => logError('Notification request error', err));
}

function hideNotificationRequest() {
    $('.js-flash-message-error, .enable-notifications').addClass('--hidden');
}

export function logPush(type, message) {
    $.ajax({
        url: Routing.generate('push_log'),
        type: 'POST',
        data: { type, message }
    });
}

function logInfo(message) {
    logPush('info', message);
}

function logError(message, error = null) {
    const fullMessage = error ? `${message}: ${error.message}` : message;
    console.error(fullMessage, error || '');
    logPush('error', fullMessage);
}